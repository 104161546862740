import React, { useState, useEffect, useContext, useRef } from "react";

import { RegionsContext, getPopularAndSelectedRegions } from "context/Regions";
import { OfferContext, loadOffer } from "context/Offer";

import {
  getDealersPageUrl,
  parseUrlToFilter,
  getSelectedRegionName,
  getQueryParams,
  getExpressionValue,
  getUpdatedExpressionValuesString,
  getUpdatedUrlSearch,
  generateCorrectUrl,
} from "utils";
import {
  getDefaultDomainFilters,
  getDefaultExpressionsAndRegions,
} from "app/utils/filtersService";
import {
  scrollWindow,
  widgetConfig,
  isWidgetMode,
  onScrollTo,
} from "app/utils/frameService";
import { getLocalText, getText } from "app/utils/i18nService";
import { useWindowSize } from "app/utils/windowSizeHook";

import NotFoundPage from "pages/not-found";

import Header from "components/Header";
import Footer from "components/Footer";
import Loading from "components/Loading";
import Seo from "components/Seo";
import PriceCard from "components/shared/PriceCard";
import CompetitorsCarousel from "components/OfferDetailPage/CompetitorsCarousel";
import Tabs from "components/shared/Tabs";
import ColorPicker from "components/shared/ColorPicker";
import AddToCompareButton from "components/shared/AddToCompareButton";
import TabContent from "components/OfferDetailPage/TabContent";
import MobileTabsContent from "components/OfferDetailPage/MobileTabsContent";
import MobileTabsList from "components/OfferDetailPage/MobileTabsList";
import MainCarousel from "components/OfferDetailPage/MainCarousel";
import LinkButton from "components/ui/LinkButton";

import "./styles.less";
import { useUpdateEffect } from "app/utils/useUpdateEffect";
import { REQUEST_STATUS } from "app/constants";

const OfferDetailPage = props => {
  const { regions, radius, selectedRegions } = useContext(RegionsContext);
  const { offer, status, functions: offerContextFunctions } = useContext(
    OfferContext,
  );
  const { isTabletWidth } = useWindowSize();
  const [state, setState] = useState({
    activeTab: getQueryParams(props.location.search).selectedTab || "short",
    isMobileTabsOpen: false,
    scrollPosition: 0,
    selectedColors: getExpressionValue(
      "1009",
      props.location.search,
    ).map(item => Number(item)),
  });
  const regionFilter = offer.filters
    ? offer.filters.find(item => item.i === 1115)
    : null;
  const hideAdditionalOptions = !regionFilter || regionFilter.enum !== 1115643;

  useEffect(() => {
    const { history, location } = props;

    history.replace(
      generateCorrectUrl(
        location,
        regions,
        selectedRegions,
        radius,
        "offer-detail",
      ),
    );

    if (status === "NONE") {
      getOfferData();
    }

    //componentWillUnmount
    return () => {
      offerContextFunctions.resetStatus();
    };
  }, []);

  useEffect(() => {
    const { isMobileTabsOpen, scrollPosition } = state;
    if (!isMobileTabsOpen) {
      scrollWindow(scrollPosition);
      if (isWidgetMode) {
        onScrollTo(scrollPosition);
      }
    }
  }, [state.isMobileTabsOpen]);

  useUpdateEffect(() => {
    getOfferData();
  }, [selectedRegions, radius]);

  const getOfferData = (search, isGetPrices = false) => {
    const { location, match } = props;

    const filters = parseUrlToFilter(
      location.pathname,
      search ? search : location.search,
    );

    const expressionsWithoutColors = filters.expressions
      ? filters.expressions.filter(
          expression => expression.indexOf("1009=") === -1,
        )
      : [];
    const variables = {
      limit: 20,
      after: "null",
      expressions: expressionsWithoutColors,
      expressionsWithColors: filters.expressions ? filters.expressions : [],
      equipmentIds: [match.params.offerId],
    };

    variables.geo =
      selectedRegions.length > 0
        ? {
            ids: selectedRegions,
            radius: radius,
          }
        : null;

    const defaultExpressions = getDefaultExpressionsAndRegions(
      variables.expressions,
    );
    variables.expressions = defaultExpressions.expressions;
    variables.domain = getDefaultDomainFilters();

    if (isGetPrices) {
      offerContextFunctions.getOfferPrices(variables);
    } else {
      offerContextFunctions.getOffer(variables);
    }
  };

  const getSeoData = () => {
    // Title: Продажа новых автомобилей Land Rover Range Rover Evoque TD4 150 Special Edition в России/Москве, цена от 2 921 050 руб
    // Description: Продажа новых автомобилей Land Rover Range Rover Evoque TD4 150 Special Edition в России/Москве, 2.0 автомат (150 л.с.) дизельное топливо, полный привод, внедорожник, цена от 2 921 050 руб
    // H1: Продажа новых автомобилей Land Rover Range Rover Evoque TD4 150 Special Edition в России/Москве

    const { price } = offer.offers ? offer.offers : {};

    const region = getSelectedRegionName(regions, selectedRegions);
    const modificationName = offer.modificationName
      ? `, ${offer.modificationName}`
      : "";
    const priceString = price && price.low ? `, цена от ${price.high} руб` : "";

    return {
      title: `Продажа новых автомобилей ${offer.title} в ${region ||
        "России"}${priceString}`,
      description: `Продажа новых автомобилей ${offer.title || ""} в ${region ||
        "России"}${modificationName}${priceString}`,
      h1: `${getLocalText("Продажа новых автомобилей")} ${offer.title ||
        ""} ${getLocalText("в")} ${region || getLocalText("России")}`,
      canonical: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`,
    };
  };

  const openMobileTab = selectedTab => {
    const scrollPosition = window.pageYOffset;
    scrollWindow(0);
    if (isWidgetMode) {
      onScrollTo(0);
    }
    setState({
      ...state,
      activeTab: selectedTab,
      isMobileTabsOpen: true,
      scrollPosition: scrollPosition,
    });
  };

  const changeSelectedColor = color => {
    let { location, history } = props;
    let { selectedColors } = state;
    const isSelected = selectedColors.some(key => key === color);
    if (isTabletWidth) {
      selectedColors = [];
    }
    if (isSelected) {
      selectedColors = selectedColors.filter(
        selectedColor => selectedColor !== color,
      );
    } else {
      selectedColors.unshift(color);
    }
    setState({ ...state, selectedColors });

    //Добавить id цветов в url
    const expressionsString = getUpdatedExpressionValuesString(
      "1009",
      selectedColors,
      location.search,
    );
    const search = getUpdatedUrlSearch(
      "expressions",
      expressionsString ? [expressionsString] : [],
      location.search,
    );
    props.history.replace(search);
    getOfferData(search, true);
  };

  const resetColors = () => {
    setState({ ...state, selectedColors: [] });
    const expressionsString = getUpdatedExpressionValuesString(
      "1009",
      [],
      props.location.search,
    );
    const search = getUpdatedUrlSearch(
      "expressions",
      expressionsString ? [expressionsString] : [],
      props.location.search,
    );
    props.history.replace(search);
    getOfferData(search, true);
  };

  const { activeTab, isMobileTabsOpen } = state;
  let { selectedColors } = state;
  const { location } = props;

  if (status === REQUEST_STATUS.FAIL) {
    return <NotFoundPage />;
  }

  const numberOfSelectedRegions = selectedRegions.length;
  const selectedRegion =
    numberOfSelectedRegions === 1
      ? regions.find(region => selectedRegions[0] === region.id).resourcePath
      : "";
  const seoData = getSeoData();

  let dealersPageUrl = "";
  if (offer && offer.model) {
    dealersPageUrl = getDealersPageUrl(
      offer.id,
      offer.model.resourcePath.replace(/^\//, ""),
      selectedRegion,
      location.search.replace(/^\?/, ""),
    );
  }

  if (offer.offers && offer.offers.colors) {
    selectedColors = selectedColors.filter(selectedColor =>
      offer.offers.colors.some(color => color.key === selectedColor),
    );
  }

  let mainCarouselSlides =
    offer.offers && offer.offers.colors ? offer.offers.colors : [];
  if (offer.offers && offer.offers.colors && selectedColors.length > 0) {
    mainCarouselSlides = [];
    selectedColors.forEach(selectedColor => {
      mainCarouselSlides.push.apply(
        mainCarouselSlides,
        offer.offers.colors.filter(color => color.key === selectedColor),
      );
    });
  }

  return (
    <div className="skeleton offer-detail">
      <Seo
        description={seoData.description}
        title={seoData.title}
        canonical={seoData.canonical}
      />
      <Header
        showBackButton={true}
        onBackButtonClick={
          isMobileTabsOpen
            ? () => {
                setState({ ...state, isMobileTabsOpen: false });
              }
            : null
        }
      />
      <div id="main" className="content">
        {isMobileTabsOpen ? (
          <MobileTabsContent
            activeTab={activeTab}
            changeTab={activeTab => {
              setState({ ...state, activeTab });
            }}
            title={offer.title}
            specifications={offer.specifications}
            description={offer.description}
          />
        ) : (
          <>
            <div className="left">
              {status !== "SUCCESS" ? (
                <Loading />
              ) : (
                <>
                  <div className="title_container">
                    <h1 className="title">{seoData.h1}</h1>
                    <AddToCompareButton offerId={offer.id} />
                  </div>
                  <div className="short">
                    {getLocalText(offer.modificationName)}
                  </div>

                  <MainCarousel slides={mainCarouselSlides} />

                  {isTabletWidth &&
                    offer.offers.totalCount > 0 &&
                    (!isWidgetMode || widgetConfig.showDealersFilters) && (
                      <ColorPicker
                        className="color-picker-mobile"
                        colors={offer.offers.colors}
                        selected={selectedColors}
                        onChange={changeSelectedColor}
                        onResetSelectedColors={resetColors}
                      />
                    )}

                  <div className="offer-detail_offers-container">
                    <span className="gray-title">
                      {getLocalText("Предложения")}
                    </span>
                    {offer.offers && (
                      <div className="offer-detail_price-card-container">
                        <PriceCard
                          offer={offer}
                          dealersPageUrl={dealersPageUrl}
                          btnComponent={
                            <LinkButton
                              color="orange"
                              text={getLocalText(
                                getText("common.dealersContacts"),
                              )}
                              disabled={offer.offers.totalCount <= 0}
                              url={dealersPageUrl}
                            />
                          }
                          filtersComponent={
                            <ColorPicker
                              colors={offer.offers.colors}
                              selected={selectedColors}
                              onChange={changeSelectedColor}
                              onResetSelectedColors={resetColors}
                            />
                          }
                        />
                      </div>
                    )}
                  </div>

                  {activeTab && offer.description && (
                    <>
                      <Tabs
                        offerId={offer.id}
                        offerPath={offer.model.resourcePath.replace(/^\//, "")}
                        selectedRegion={selectedRegion}
                        active={activeTab}
                        changeTab={activeTab => {
                          setState({ ...state, activeTab });
                        }}
                        search={location.search}
                      />
                      <div className="tab-content_container">
                        <TabContent
                          tab={activeTab}
                          description={offer.description}
                          specifications={offer.specifications}
                          offerTitle={offer.title}
                          hideAdditionalOptions={hideAdditionalOptions}
                        />
                      </div>
                    </>
                  )}

                  <MobileTabsList
                    openTab={selectedTab => {
                      openMobileTab(selectedTab);
                    }}
                    offerId={offer.id}
                    offerPath={offer.model.resourcePath.replace(/^\//, "")}
                    selectedRegion={selectedRegion}
                    search={location.search}
                  />

                  {offer.offers &&
                    offer.offers.competitors &&
                    offer.offers.competitors.edges &&
                    offer.offers.competitors.edges.length !== 0 && (
                      <CompetitorsCarousel
                        selectedRegion={selectedRegion}
                        competitors={offer.offers.competitors}
                      />
                    )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {!isMobileTabsOpen && <Footer />}
    </div>
  );
};

OfferDetailPage.fetchData = (dataContext, match, request) => {
  let regionsFromCookies = dataContext.regions || {};
  const { selectedRegions = [], radius = 200 } = regionsFromCookies;
  const params = parseUrlToFilter(
    request.originalUrl.split("?")[0],
    request.originalUrl.split("?")[1],
  );

  return new Promise((resolve, reject) => {
    getPopularAndSelectedRegions(
      params.region,
      params.radius,
      selectedRegions,
    ).then(regions => {
      regions.selectedRegions = regions.selectedRegions || selectedRegions;
      regions.radius = regions.radius !== undefined ? regions.radius : radius;
      regions = {
        ...regionsFromCookies,
        ...regions,
      };
      const expressionsWithoutColors = params.expressions
        ? params.expressions.filter(
            expression => expression.indexOf("1009=") === -1,
          )
        : [];
      const vars = {
        limit: 20,
        after: "null",
        expressions: expressionsWithoutColors,
        expressionsWithColors: params.expressions ? params.expressions : [],
        equipmentIds: [params.version],
        geo: Object.assign(
          { ids: regions.selectedRegions },
          regions.radius !== undefined ? { radius: regions.radius } : {},
        ),
        domain: {},
      };

      loadOffer(vars)
        .then(result => {
          resolve({
            isDataContext: true,
            regions,
            offer: result,
          });
        })
        .catch(() => {
          reject();
        });
    });
  });
};
export const OfferDetailSSRLoad = OfferDetailPage.fetchData;

export default OfferDetailPage;
