import { Lokka } from "lokka";
import HttpTransport from "lokka-transport-http";
import * as Sentry from "@sentry/browser";
import { isWidgetMode, widgetConfig } from "app/utils/frameService";

let client = null;

const createClient = () => {
  client = new Lokka({
    transport: new HttpTransport(
      global.hostURL ||
        `${window.location.protocol}//${window.location.host}/graphql`,
      {
        credentials: false,
        handleErrors: handleErrors,
        headers: {
          ...(isWidgetMode && widgetConfig?.language
            ? { "Content-Language": widgetConfig?.language }
            : {}),
          ...(isWidgetMode && widgetConfig?.contentMarketHeader
            ? { "Content-Market": widgetConfig?.contentMarketHeader }
            : {}),
        },
      },
    ),
  });
};

let response = null;
const handleErrors = (errors, data) => {
  if (!data) {
    let message = errors[0].message;
    let error = new Error("GraphQL Error: " + message);
    error.rawError = errors;
    error.rawData = data;
    throw error;
  } else {
    console.log("GraphQL errors");
    let errorsString = "";
    errors.forEach((error, index) => {
      let errorString = "";
      let locationString = "";
      let pathString = "";
      errorString += `-Error ${index + 1}: ${error.message} \n`;
      error.locations.forEach((location, locationIndex) => {
        locationString += `--Location ${locationIndex + 1}: column = ${
          location.column
        }; line = ${location.line} \n`;
      });
      error.path.forEach((path, pathIndex) => {
        pathString += `--Path ${pathIndex + 1}: ${path} \n`;
      });
      errorsString += errorString + locationString + pathString;
    });
    console.log(errorsString);
    Sentry.captureException("Non critical GraphQL bugs");
    response = data;
  }
};

export default {
  fetch: (query, variables, controller) => {
    if (!client) {
      createClient();
    }
    return new Promise((resolve, reject) => {
      client
        .query(query, variables)
        .then(result => {
          if (response) {
            resolve(response);
            response = null;
          } else {
            resolve(result);
          }
        })
        .catch(error => {
          if (document.createEvent) {
            const event = document.createEvent("Event");
            event.initEvent("catchErrorFromApi", true, true);
            window.dispatchEvent(event);
          }
        });
      if (controller) {
        controller.cancel = () => {
          reject("canceled");
        };
      }
    });
  },
};

export const getRegionFromLatLng = (lat, lng) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://api.opencagedata.com/geocode/v1/json?key=471d6797401d4e98abc894f122ca425b&q=${lat},${lng}&language=ru`,
    ).then(response => {
      if (response.ok) {
        response.json().then(result => {
          if (result.results[0].components.country === "Россия") {
            resolve({
              city: result.results[0].components.city,
              region: result.results[0].components.state,
            });
          } else {
            reject();
          }
        });
      } else {
        reject();
      }
    });
  });
};
